.resultsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px;
  container {
    display: flex;
    > div {
      max-width: 33vw;
      margin: 0px 20px;
    }
  }
  h2 {
    text-align: center;
  }
}

.speedometerDiv {
  min-width: 320px;
  min-height: calc(320px / 1.6);
  width: 33vw;
  height: calc(33vw / 1.6);
}

.subCategoriesTitle {
  font-weight: bold;
  font-size: 1.5rem;
}

.buttonsDiv {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  margin: 10px 10px;
  flex-wrap: wrap;
  Button {
    margin-bottom: 8px;
    font-size: 0.75rem;
  }
}

.overallResults {
  display: block;
  margin: auto;
  text-align: center;
  .speedometerDiv {
    margin: auto;
  }
}

.overallText {
  div {
    padding: 20px;
    max-width: 1440px;
    width: 90vw;
    margin: auto;
    display: block;
    margin-bottom: 20px;
    color: black;
  }
  .currentLevelText {
    background-color: lightgray;
  }
  .nextLevelText {
    background-color: lightgray;
  }
}

.overallIndividualCategories,
.subCategoriesContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 1440px;
  flex-wrap: wrap;
  margin: auto;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 50px;

  div {
    width: 30vw;
    max-width: 420px;
    background-color: #cad7ea;
    padding: 25px 0px;
    border-radius: 10px;
    border: 1px solid #052e5e;
    align-self: stretch;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .overallIndividualCategoriesSectionTitle {
      margin-bottom: 5px;
    }
    p,
    h4 {
      text-align: center;
      margin: 0px 5px 0px 5px;
      margin-bottom: 5px;
    }
    h4 {
      text-decoration: underline;
    }
    .subCategorySubtitle {
      font-weight: 500;
    }
    button {
      color: white;
      border: 1px solid #052e5e;
      border-radius: 2px;
      background-color: #052e5e;
      padding: 6px 12px;
      cursor: pointer;
      font-weight: bold;
      margin-top: 10px;
    }
    button:hover {
      background-color: #ff5c00;
      border-color: #ff5c00;
    }
  }
}

.insightsContainer {
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  max-width: 1440px;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 50px;
  .insightsDiv {
    width: 28vw;
    padding: 20px 10px;
    margin-bottom: 50px;
    background-color: #052e5e;
    color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    h3 {
      text-decoration: underline;
      text-align: center;
    }
  }
}
div.speedometerDiv > div > svg > g:nth-child(3) > text {
  display: none;
}

.legendDiv {
  display: flex;
  align-items: center;
  column-gap: 30px;
  justify-content: center;
}

.legend {
  display: flex;
  align-items: center;
  p {
    margin-left: 5px;
  }
}

.legend1,
.legend2,
.legend3,
.legend4,
.legend5 {
  width: 20px;
  height: 20px;
}

.legend1 {
  background-color: red;
}

.legend2 {
  background-color: orange;
}

.legend3 {
  background-color: #c9b300;
}

.legend4 {
  background-color: #0a7c23;
}

.legend5 {
  background-color: #1bb504;
}

.categoryScore {
  font-size: 1.2rem;
}



@media only screen and (min-device-width: 1400px) {
  .MuiTabs-flexContainer {
    justify-content: center;
  }
}

@media only screen and (max-device-width: 1200px) {
  .insightsContainer {
    flex-wrap: wrap;
    .insightsDiv {
      width: 40vw;
    }
  }
  .legendDiv {
    flex-wrap: wrap;
  }
}

@media only screen and (max-device-width: 500px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .insightsContainer {
    .insightsDiv {
      width: 90vw;
    }
  }

  .subCategoriesContainer {
    div {
      width: 90vw;
    }
  }

  .overallIndividualCategories {
    div {
      width: 40vw;
    }
  }
}
