body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin: auto;
  font-family: "Regular", sans-serif !important;
  line-height: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("reader-regular-pro"),
    url("./fonts/reader-regular-pro.ttf") format("truetype");
}

.sv-body,
.sv-title,
button {
  font-family: Regular;
}
